import { gql } from "@apollo/client";
import { AssetFragment } from "../../helper/asset/fragments";

export const ImageTextFragment = gql`
  fragment ImageTextFragment on ComponentCoreImageText {
    smallTitle
    largeTitle
    paragraph
    containerWidth
    backgroundColour
    image { 
      ...AssetFragment
      width
      height
    }
    imageAlignment
  }
  ${AssetFragment}
`