import { Link } from "react-router-dom"
import { useAppSelector } from "../../../../../store/hooks"
import { getSettingsShortFooter, getSettingsFooterCopyright, getSettingsFooterState } from "../../../../../store/slices/settings/slice"

function Footer () {
  const footer = useAppSelector(getSettingsShortFooter)
  const footerState = useAppSelector(getSettingsFooterState)
  const copyright = useAppSelector(getSettingsFooterCopyright)

  return (
    <div className={`footer-container${footerState ? "" : " default"}`}>
      <div>
        <ul className="footer-links">
          {footer && footer.map((item, key) => (
            <Link to={item.url} key={key}>
              <li>
                <p>{item.title}</p>
              </li>
            </Link>
          ))}
        </ul>
        <p>{copyright}</p>
      </div>
      <div className="flex-end">
        <ul>
          <a href="https://www.instagram.com/montaguelondon/">
            <li>
              <img className="social-icons" src="https://montague-developments-temp-bucket.s3.eu-west-1.amazonaws.com/instagram-logo-white.png" alt="Montague Developments Instagram" />
            </li>
          </a>
          <a href="https://www.linkedin.com/company/montaguelondon/">
            <li>
              <img className="social-icons" src="https://montague-developments-temp-bucket.s3.eu-west-1.amazonaws.com/linkedIn-logo-white.png" alt="Montague Developments LinkedIn" />
            </li>
          </a>
          <a href="https://www.twitter.com/montaguelondon/">
            <li>
              <img className="social-icons" src="https://montague-developments-temp-bucket.s3.eu-west-1.amazonaws.com/twitter-logo-white.png" alt="Montague Developments Twitter" />
            </li>
          </a>
        </ul>
      </div>
    </div>
  )
}

export default Footer