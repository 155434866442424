import { TextParagraphType } from './model'
import { Text } from '../../helper'

interface TextParagraphProps {
  component: {
    componentCoreTextParagraph: TextParagraphType
  }
}

export function TextParagraph({component}: TextParagraphProps) {
  const { title, titleSize, textCentered, column, paragraph, containerWidth, accent } = component.componentCoreTextParagraph

  return (
    <div className={`content-wrapper${containerWidth ? "-small" : ""}`}>
      <Text title={title} titleSize={titleSize} textCentered={textCentered} column={column} paragraph={paragraph} accent={accent}></Text>
    </div>
  )
}


export default TextParagraph;