import { gql } from "@apollo/client"
import { TimelineFragment } from "../../schema"

export const QUERY_TIMELINE_BY_ID = gql`
  query ($id: String!) {
    componentCoreTimeline(id: $id) {
      ...TimelineFragment
    } 
  }
  ${TimelineFragment}
`

export default QUERY_TIMELINE_BY_ID