import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..'
import { Navigation, SettingsState } from './model'
import { QUERY_SITE_SETTINGS } from './queries'
import { client } from '../../client'
import { settings } from 'cluster';

const initialState: SettingsState = {
  loaded: false,
  fields: null,
  footerState: false
}

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async () => {
    const query = await client.query({ query: QUERY_SITE_SETTINGS })
    if (query.data) return query.data.siteSettings
  }
)

// Settings Slice
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLoaded: (state: SettingsState) => { state.loaded = true },
    setFooterState: (state: SettingsState, action: PayloadAction<boolean>) => { state.footerState = action.payload }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSettings.fulfilled, (state: SettingsState, action: PayloadAction<any>) => {
        state.loaded = true
        state.fields = action.payload
      }
    )
  }
})

// Export Dispatch Actions
export const { setLoaded, setFooterState } = settingsSlice.actions;

// Export Selectors
// export const getSettingsLoaded = (state: RootState): boolean => state.settings.loaded;
export const getSettingsLogos = (state: RootState): any => ({
  blackLogo: state.settings.fields?.blackLogo, 
  whiteLogo: state.settings.fields?.whiteLogo
})
export const getSettingsNavigation = (state: RootState): [Navigation]| null => state.settings.fields?.navigationCollection ? state.settings.fields.navigationCollection.items : null
export const getSettingsShortFooter = (state: RootState): [Navigation]| null => state.settings.fields?.shortFooterCollection ? state.settings.fields.shortFooterCollection.items : null
export const getSettingsFooterCopyright = (state: RootState): string => state.settings.fields?.copyright ? state.settings.fields.copyright : ""
export const getSettingsFooterState = (state: RootState): boolean => state.settings.footerState
// export const settingsFooter = (state: RootState): [NavigationType | null, string | null] => [state.settings.fields.footerCollection ? state.settings.fields.footerCollection : null, state.settings.fields.copyright ? state.settings.fields.copyright : null]
// export const getContactData = (state: RootState) => [state.settings.fields.emailAddress, state.settings.fields.contactNumber, state.settings.fields.contactAddress]
// export const getLegalPopUp = (state: RootState) => state.settings.fields.legalPopUp

// Export Reducer
export default settingsSlice.reducer;