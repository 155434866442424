import React, { useReducer, Reducer } from "react";
import { Text } from "../../helper";
import styles from "./Contact.module.sass";
import { concatForm } from "./helper";
import emailjs from "emailjs-com";
import { error } from "console";

interface ContactFormState {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  enquiry: string;
  success: string | null;
  submitting: boolean;
  error: string | null;
}

interface InputFieldProps {
  title: string;
  name: string;
  value: string;
  action: (any: any) => void;
}

const InputField = ({ title, name, value, action }: InputFieldProps) => (
  <fieldset className={styles.fieldSetContainer}>
    <legend className={styles.fieldLegend}>
      <Text paragraph={title} accent={true} />
    </legend>
    <span className={styles.fieldsWrapper}>
      <input
        id={name}
        name={title}
        value={value}
        onChange={(e) => action({ [name]: e.target.value })}
        placeholder={title}
        className={styles.textInput}
      />
    </span>
  </fieldset>
);

const TextAreaField = ({ title, name, value, action }: InputFieldProps) => (
  <fieldset className={styles.fieldSetContainer}>
    <legend className={styles.fieldLegend}>
      <Text paragraph={title} accent={true} />
    </legend>
    <span className={styles.fieldsWrapper}>
      <textarea
        rows={8}
        id={name}
        name={title}
        value={value}
        onChange={(e) => action({ [name]: e.target.value })}
        placeholder={title}
        className={styles.textInput}
      />
    </span>
  </fieldset>
);

function ContactForm() {
  const [state, setState] = useReducer<
    Reducer<ContactFormState, Partial<ContactFormState>>
  >((state, newState) => ({ ...state, ...newState }), {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    enquiry: "",
    success: null,
    submitting: false,
    error: null,
  });

  const handleContactFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    const message = concatForm(Object.fromEntries(new FormData(e.target)));

    if (message.error !== "")
      return setState({
        error: message.error,
        submitting: false,
        success: null,
      });
    const templateParams = { message: message.result };

    try {
      emailjs
        .send(
          "service_qp1qsvp",
          "template_mwchcmq",
          templateParams,
          "user_6JHKK7TkJftHvnpia4jwu"
        )
        .then(() => {
          setState({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            emailAddress: "",
            enquiry: "",
            success:
              "We've received your enquiry, we aim to responded to everyone with 48hours.",
            submitting: false,
            error: null,
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.contactFormContainer}>
      {state.success && (
        <div className={styles.success}>
          <Text paragraph={state.success} accent={true} />
        </div>
      )}

      {state.error && (
        <div className={styles.errorMessage}>
          <Text paragraph={state.error} />
        </div>
      )}

      <form onSubmit={handleContactFormSubmit}>
        <div className={styles.fieldGroup}>
          <InputField
            title="First Name"
            name="firstName"
            value={state.firstName}
            action={setState}
          />
          <InputField
            title="Last Name"
            name="lastName"
            value={state.lastName}
            action={setState}
          />
        </div>

        <div className={styles.fieldGroup}>
          <InputField
            title="Phone Number"
            name="phoneNumber"
            value={state.phoneNumber}
            action={setState}
          />
          <InputField
            title="Email Address"
            name="emailAddress"
            value={state.emailAddress}
            action={setState}
          />
        </div>

        <div>
          <TextAreaField
            title="Enquiry"
            name="enquiry"
            value={state.enquiry}
            action={setState}
          />
        </div>

        <button
          disabled={state.submitting}
          className={styles.submitButton}
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
