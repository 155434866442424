import { gql } from "@apollo/client"

export const QUERY_ALL_PAGES = gql`{
  pageCollection {
    items {
      sys { id }
      title
      url
      metaDescription
      fullscreen
    }
  }
}`