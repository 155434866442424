import { gql } from "@apollo/client"

export const QUERY_VERTICAL_SPACER_BY_ID = gql`
  query ($id: String!) {
    componentCoreVerticalSpacer(id: $id) {
      space
      mobileSpace
    }
  }
`

export default QUERY_VERTICAL_SPACER_BY_ID