import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useAppSelector } from "../../../../../store/hooks"
import { getSettingsLogos } from "../../../../../store/slices/settings/slice"
import { SideMenuView } from "./sideMenu"


function Navigation () {
  const logos = useAppSelector(getSettingsLogos)
  const [navState, setNavState] = useState<boolean>(false)

  return (
    <React.Fragment>
      <div className="navigation">
        <div className="logo-container">
          <Link to="/">
            <img src={logos.whiteLogo?.url} alt="logo" />
          </Link>
        </div>

        <div className="burger-container" onClick={() => setNavState(!navState)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <SideMenuView navState={navState} setNavState={setNavState} />
    </React.Fragment>
  )
}

export default Navigation