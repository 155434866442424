import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import pageReducer from './slices/pages/slice';
import settingsReduer from './slices/settings/slice'

export const store = configureStore({
  reducer: {
    pages: pageReducer,
    settings: settingsReduer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
