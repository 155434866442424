import { gql } from "@apollo/client";

export const TextParagraphFragment = gql`
  fragment TextParagraphFragment on ComponentCoreTextParagraph {
    title
    titleSize
    textCentered
    containerWidth
    paragraph
    column
    accent
  }
`