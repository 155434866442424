import { gql } from "@apollo/client";
import { AssetFragment } from "../../helper/asset/fragments";


export const HeroBannerFragment = gql`
  fragment HeroBannerFragment on ComponentCoreHeroBanner {
    title
    mediaCollection (limit: 5) {
      items {
        ... AssetFragment
      }
    }
  }
  ${AssetFragment}
`