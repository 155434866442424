export const concatForm = (values: { [k: string]: FormDataEntryValue; }): { result: string | null, error: string | null } =>  {
  var result = ""
  var error = ""

  for (const [key, value] of Object.entries(values)) {
    if (value === "") return {result: null, error: error += `Please Enter Your ${key}.` }
    result += `<b>${key}:</b> ${value}<br>`
  }

  result += `<b>URL:</b> ${window.location.href}<br>`
  return {result, error}
}