import { gql } from "@apollo/client"
import { TeamContainerFragment } from "../../schema"

export const QUERY_TEAM_CONTAINER_BY_ID = gql`
  query ($id: String!) {
    componentCoreTeamContainer(id: $id) {
      ...TeamContainerFragment
    } 
  }
  ${TeamContainerFragment}
`

export default QUERY_TEAM_CONTAINER_BY_ID