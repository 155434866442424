import { gql } from "@apollo/client"
import { ImageTextFragment } from "../../schema"

export const QUERY_IMAGE_TEXT_BY_ID = gql`
  query ($id: String!) {
    componentCoreImageText(id: $id) {
      ...ImageTextFragment
    } 
  }
  ${ImageTextFragment}
`

export default QUERY_IMAGE_TEXT_BY_ID