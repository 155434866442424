import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Page } from '../../store/slices/pages/models'
import { getAllPages, selectAllPages } from '../../store/slices/pages/slice'
import { fetchSettings } from '../../store/slices/settings/slice'
import Footer from '../componentLoader/components/core/footer'
import Navigation from '../componentLoader/components/core/navigation'
import { PageLoader } from '../pageLoader'
import E404 from '../componentLoader/components/core/e404'

function AppRouter() {
  const dispatch = useAppDispatch()
  const pages = useAppSelector(selectAllPages)


  useEffect(() => {
    dispatch(fetchSettings())
    dispatch(getAllPages())
  })

  return (
    <BrowserRouter>    
      <div className="App">
        
        <Navigation />
          <Routes>
            {pages && (
              <React.Fragment>
                {pages.map((page: Page, key: number) => (
                  <Route key={key} path={page.url} element={<PageLoader page={page} />} />
                ))}
                <Route path="*" element={<E404 />} />
              </React.Fragment>
            )}
          </Routes>
          <Footer />
        </div>
    </BrowserRouter>
  )
}

export default AppRouter