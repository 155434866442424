import { Asset } from "../../helper/asset/model"
import { HeroBannerType } from "./model"
import styles from "./HeroBanner.module.sass"

interface HeroBannerProps {
  component: {
    componentCoreHeroBanner: HeroBannerType
  }
}

const HeroImage = ({media}: {media: Asset}) => (
  <img src={media.url} alt={media.title} />
)

function HeroBanner ({component}: HeroBannerProps) {
  const { title, mediaCollection } = component?.componentCoreHeroBanner

  return (
    <div className={styles.heroBannerContainer}>
      <div className={styles.heroBannerContent}>
        <h1 style={{
          textTransform: "uppercase",
          color: "#fff",
          fontSize: "36px",
          fontWeight: 400
        }}>{title}</h1>
      </div>
      {mediaCollection.items.length === 1 ? (
        <HeroImage media={mediaCollection.items[0]} />
      ) : (
        <div>
        {mediaCollection.items.map((item, key) => (
          <HeroImage media={item} key={key} />
        ))}
        </div>
      )}
    </div>
  )
}

export default HeroBanner