import { createSlice, createAsyncThunk, PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { QUERY_ALL_PAGES } from './query'
import { Page } from './models';
import { client } from '../../client';

export interface PageState {
  data: Page[]
}

const initialState: PageState = {
  data: []
}

export const getAllPages = createAsyncThunk(
  'pages/getAllPages',
  async () => {
    const query = await client.query({ query: QUERY_ALL_PAGES })
    if (query.data) return query.data.pageCollection.items as Page[]
  }
)

export const pageSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PageState>) => {
    builder.addCase(
      getAllPages.fulfilled, (state: PageState, action: PayloadAction<Page[] | undefined>) => { 
        state.data = action.payload as Page[]
      }
    )
  }
})

export const selectAllPages = (state: RootState) => state.pages.data;

export default pageSlice.reducer;