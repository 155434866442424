import { TimelineType } from './model'
import { Text } from '../../helper'
import styles from './timeline.module.sass'

interface TimelineProps {
  component: {
    componentCoreTimeline: TimelineType
  }
}

export function Timeline({component}: TimelineProps) {
  const { title, titleSize, momentCollection } = component.componentCoreTimeline

  return (
    <div className="content-wrapper-small">
      <div className={styles.timelineTable}>
        
        <div className={styles.timelineTableRow}>
          <div className={styles.timelineCol}></div>
          <div className={""}>
            <Text title={"BUILT TO CREATE"} largeTitle={"A SENSE OF PLACE"}  titleSize={titleSize} textCentered={"Left"}></Text>
          </div>
        </div>

        {momentCollection.items && momentCollection.items.map((moment, key) => (
          <div className={styles.timelineTableRow} key={key}>
            <div className={styles.timelineCol}>
              <div className={styles.timelineYear}>
                <Text title={moment.year} titleSize={"Heading 1"} textCentered={"Center"} titleColour={"#0c4c25"}></Text>
              </div>
              {key < (momentCollection.items.length - 1) && <div className={styles.timelineLine}></div>}
           </div>
            <div className={styles.timelineCol}>
             <Text title={moment.title} titleSize={titleSize} textCentered={"Left"} paragraph={moment.paragraph}></Text>
           </div>
          </div>  
        ))}
    
      </div>  
    </div>
  )
}


export default Timeline;