import './styles.sass'
import './styles.scss'
import { client } from './store/client'
import { ApolloProvider } from '@apollo/client';
import AppRouter from './features/appRouter'
import { store } from './store';
import { Provider } from 'react-redux';

function App() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
     </ApolloProvider>
  );
}

export default App;
