import { gql } from "@apollo/client"
import { FullScreenComponentWrapperFragment } from "../../schema"

export const QUERY_FULLSCREEN_COMPOENT_WRAPPER_BY_ID = gql`
  query ($id: String!) {
    componentCoreFullScreenComponentWrapper(id: $id) {
      ...FullScreenComponentWrapperFragment
    } 
  }
  ${FullScreenComponentWrapperFragment}
`

export default QUERY_FULLSCREEN_COMPOENT_WRAPPER_BY_ID