import { ImageTextType } from './model'
import { Text } from '../../helper'
import styles from './ImageText.module.sass'

interface ImageTextProps {
  component: {
    componentCoreImageText: ImageTextType
  }
}

export function ImageText({component}: ImageTextProps) {
  const { smallTitle, largeTitle, paragraph, imageAlignment, containerWidth, backgroundColour, image } = component.componentCoreImageText

  console.log(imageAlignment)

  return (
    <div className={styles.imageTextContainer} style={{
      backgroundColor: backgroundColour ? backgroundColour : "#fff"
    }}>
      <div className={`content-wrapper${containerWidth ? "-small " + styles.contentWrapperSmall :  " " + styles.contentWrapper}${imageAlignment ? " " + styles.rtl : ""}`}>
        <div className={styles.imageTextImageContainer}>
          <img className={image.width - image.height < 0 ? styles.verticalImage: ""} src={image.url} alt={image.title} />
        </div>

        <div className={styles.imageTextTextContainer}>
          <Text title={smallTitle} largeTitle={largeTitle} titleSize={"Heading 2"} textCentered={"Left"} paragraph={paragraph}></Text>
       </div>
      </div>
    </div>
  )
}


export default ImageText;