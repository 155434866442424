import { ContactUshType } from './model'
import { Text } from '../../helper'
import ContactForm from './form'
import styles from './Contact.module.sass'

interface TextParagraphProps {
  component: {
    componentCoreContactUs: ContactUshType
  }
}

export function ContactUs({component}: TextParagraphProps) {
  const { title, paragraph } = component.componentCoreContactUs

  return (
    <div className={`content-wrapper`}>
      <div className={styles.gridContainer}>
        <div>
          <Text title={title} titleSize={"Heading 2"} textCentered={"Left"} paragraph={paragraph} accent={true}></Text>
        </div>
        <div>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}


export default ContactUs;