import { Text } from '../../helper'

const E404 = () => (
  <div className="page-wrapper">
    <div className="componets">
      <div className="full-page-container" style={{
        backgroundColor: "#00210d",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Text title="404 Page Not Found" paragraph="Page not found, please return to the homepage." accent={true} />
      </div>
    </div>
  </div>
)

export default E404