import { gql } from "@apollo/client"
import { TextParagraphFragment } from "../../schema"

export const QUERY_TEXT_PARAGRAPH_BY_ID = gql`
  query ($id: String!) {
    componentCoreTextParagraph(id: $id) {
      ...TextParagraphFragment
    } 
  }
  ${TextParagraphFragment}
`

export default QUERY_TEXT_PARAGRAPH_BY_ID