import { gql } from "@apollo/client"
import { HeroBannerFragment } from "../../schema"

export const QUERY_HERO_BANNER_BY_ID = gql`
  query ($id: String!) {
    componentCoreHeroBanner(id: $id) {
      ...HeroBannerFragment
    } 
  }
  ${HeroBannerFragment}
`

export default QUERY_HERO_BANNER_BY_ID