import { gql } from "@apollo/client"
import { AssetFragment } from "../../../features/componentLoader/components/helper/asset/fragments"

const SiteSettingsFragment = gql`
  fragment SiteSettingsFields on SiteSettings {
    blackLogo {
      ... AssetFragment
    }
    whiteLogo {
      ... AssetFragment
    }
    navigationCollection (limit: 10) {
      items {
        title
        url
      }
    }
    shortFooterCollection (limit: 5) {
      items {
        title
        url
      }
    }
    copyright
  }
  ${AssetFragment}
`

export const QUERY_SITE_SETTINGS = gql`
  ${SiteSettingsFragment}
  query { 
    siteSettings(id: "6CQGFhYDVxkUU7E3UEZaTK") {
      ...SiteSettingsFields
    }
  }
`



