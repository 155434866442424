
export interface ComponentsNode {
  component: any
  query?: any
}

export let Components: { [key: string]: ComponentsNode} = {
  "ComponentCoreHomePage": {
    component: require('./core/homepage').default,
    query: require('./core/homepage/queries').default
  }, "ComponentCoreHeroBanner": {
    component: require('./core/heroBanner').default,
    query: require('./core/heroBanner/queries').default
  }, "ComponentCoreTextParagraph": {
    component: require('./core/textParagraph').default,
    query: require('./core/textParagraph/queries').default
  }, "ComponentCoreVerticalSpacer": {
    component: require('./core/verticalSpacer').default,
    query: require('./core/verticalSpacer/queries').default
  }, "ComponentCoreTimeline": {
    component: require('./core/timeline').default,
    query: require('./core/timeline/queries').default
  }, "ComponentCoreFullScreenComponentWrapper": {
    component: require('./core/fullScreenWrapper').default,
    query: require('./core/fullScreenWrapper/queries').default
  }, "ComponentCoreImageText": {
    component: require('./core/imageText').default,
    query: require('./core/imageText/queries').default
  }, "ComponentCoreContactUs": {
    component: require('./core/contact').default,
    query: require('./core/contact/queries').default
  }, "ComponentCoreTeamContainer": {
    component: require('./core/teamContainer').default,
    query: require('./core/teamContainer/queries').default
  }
};


