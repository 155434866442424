import { gql } from "@apollo/client"
import { ContactUsFragment } from "../../schema"

export const QUERY_CONTACT_US_BY_ID = gql`
  query ($id: String!) {
    componentCoreContactUs(id: $id) {
      ...ContactUsFragment
    } 
  }
  ${ContactUsFragment}
`

export default QUERY_CONTACT_US_BY_ID