import { gql } from "@apollo/client";

export const FullScreenComponentWrapperFragment = gql`
  fragment FullScreenComponentWrapperFragment on ComponentCoreFullScreenComponentWrapper {
    backgroundColour
    componentsCollection (limit: 10) {
      items {
        sys {
          id
        }
      }
    }
  }
`