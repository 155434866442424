import { ComponentLoader } from '../../..';
import { FullScreenWrapperType, Component } from './model'

interface FullScreenWrapperProps {
  component: {
    componentCoreFullScreenComponentWrapper: FullScreenWrapperType
  }
}

export function FullScreenWrapper({component}: FullScreenWrapperProps) {
  const { backgroundColour, componentsCollection } = component.componentCoreFullScreenComponentWrapper

  return (
    <div className="full-page-container" style={{
      backgroundColor: backgroundColour,
      display: "flex",
      alignItems: "center"
    }}>
      {componentsCollection && componentsCollection.items.map((component: Component, key: number) => (
          <ComponentLoader key={key} component={component}  />
        ))}
    
    </div>
  )
}


export default FullScreenWrapper;