import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { Text } from '../../helper'
import Slider, {Settings} from "react-slick";
import { HomePageType, HomePageSlideItem, HomePageSlideItemLink } from "./model"

// https://www.npmjs.com/package/react-swipeable

interface HomePageProps {
  component: {
    componentCoreHomePage: HomePageType
  }
}

interface HomePageSlideViewProps {
  slide: HomePageSlideItem
}

interface HomePageSlideLinkProps {
  linkItem: HomePageSlideItemLink
}

const HomePageSlideLink = ({linkItem}: HomePageSlideLinkProps) => (
  <div className="home-link-item">
    <Link to={linkItem.url}>
      <img src={linkItem.thumbnail.url} alt={linkItem.thumbnail.title} />
      <h2>{linkItem.title}</h2>
      <p>{linkItem.paragraph}</p>
    </Link>
  </div>
)

const HomePageSlideView = ({slide}: HomePageSlideViewProps) => (
  <div className="full-page-container">
    <div className="page">
      <div className="content-container">
        <Text title={slide.smallTitle} largeTitle={slide.bigTitle} titleSize={"Heading 1"}></Text>


        {slide.buttonTitle && (
          <div className="button">
            <Link to={slide.buttonUrl}>
              <Text title={slide.buttonTitle} titleSize={"Heading 2"}></Text>
            </Link>
          </div>
        )}
        
        <div className="home-link-container">
          {slide.linkedItemsCollection.items.map((item, key) => (
            <HomePageSlideLink  key={key} linkItem={item} />
          ))}
        </div>
      </div>
    </div>

    <div className="background-container">
      <div className="opacity-overlay"></div>
      <img src={slide.backgroundMedia.url} alt={slide.backgroundMedia.title} />
    </div>
    
  </div>
)

function Homepage ({component}: HomePageProps) {
  const { homePageSlidesCollection } = component?.componentCoreHomePage
  const [scrollingState, setScrollingState] = useState<boolean>(false)
  
  const [slider, setSlider] = useState<Slider>()
  const settings: Settings = {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    draggable: true,
    verticalSwiping: true
  }

  const scroll = (e: any) => {
    if (slider) {
      if (!scrollingState) {
        setScrollingState(true)
        
        if (e.wheelDelta > 1)
          slider.slickPrev()

        if (e.wheelDelta < -1)
          slider.slickNext()
      }
    }
    setTimeout(() => {
      setScrollingState(false)
    }, 500)
  }

  useEffect(() => {
    window.addEventListener("wheel", scroll,true);

    return () => {
        window.removeEventListener("wheel", scroll, true);
    };
  });

  return (
    <div className="homeWrapper">
      <style dangerouslySetInnerHTML={{__html:`
        @media screen and (max-width: 450px) {
          body {
            position: fixed; 
            height: 100%;
          }
        `}} />
      {homePageSlidesCollection.items.length > 1 ? (
        <Slider {...settings} ref={(component: Slider) => { setSlider(component) }}>
          {homePageSlidesCollection.items.map((item, key) => (
            <HomePageSlideView key={key} slide={item} />
            ))}  
        </Slider>
      ) : (
        <HomePageSlideView slide={homePageSlidesCollection.items[0]} />
      )}
      
  </div>
  )
}

export default Homepage