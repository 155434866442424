import { gql } from "@apollo/client";
import { AssetFragment } from "../../helper/asset/fragments";

export const TeamContainerFragment = gql`
  fragment TeamContainerFragment on ComponentCoreTeamContainer {
    title
    paragraph
    membersCollection (limit: 10) {
      items {
        profileImage {
          ...AssetFragment
        }
        fullName
        jobTitle
        blurb
      }
    }
  }
  ${AssetFragment}
`