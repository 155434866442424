import { TeamContainerType, Member } from "./model"
import { Text } from '../../helper'
import TextTruncate from 'react-text-truncate'; 
import styles from './TeamContainer.module.sass'
import React, { useState } from "react";

interface TeamContainerProps {
  component: {
    componentCoreTeamContainer: TeamContainerType
  }
}

function TeamMember({member}: {member: Member}) {
  const [state, setState] = useState<boolean>(false)

  return (
    <div className={styles.teamMembersItem}>
      <img src={member.profileImage?.url} alt={member.profileImage?.title} />
      <div className={styles.teamMembersItemContent}>
        <Text title={member.jobTitle} largeTitle={member.fullName} titleSize={"Heading 3"} textCentered={"Left"} />
        <TextTruncate line={4} element="p" truncateText="..." text={member.blurb} />
        <p style={{marginTop: "20px"}} onClick={() => setState(true)}>Read More</p>
      </div>
  
      <div className={`${styles.teamMembersOverlay}${state ? " " + styles.active : ""}`}>
        <div className={styles.teamMembersOverlayContent}>
          <img src={member.profileImage?.url} alt={member.profileImage?.title} />
          <div className={styles.teamMembersOverlayBlurb}>
            <p className={styles.teamMembersOverlayClose} onClick={() => setState(false)}>Close</p>
            <Text title={member.jobTitle} largeTitle={member.fullName} titleSize={"Heading 3"} textCentered={"Left"} paragraph={member.blurb} />
          </div>
        </div>
        <div className={styles.teamMembersBackground} onClick={() => setState(false)}></div>
      </div>
    </div>
  )
}

export function TeamContainer({component}: TeamContainerProps) {
  const { title, paragraph, membersCollection } = component.componentCoreTeamContainer

  return (
    <div className={`content-wrapper ${styles.teamMembersWrapper}`}>
      <Text title={title} titleSize={"Heading 2"} textCentered={"Left"} paragraph={paragraph} />

      <div className={styles.teamMembersContainer}>
        {membersCollection.items?.map((item: Member, key: number) => (
          <TeamMember key={key} member={item} />
        ))}
      </div>
    </div>
  )
}

export default TeamContainer