import { gql } from "@apollo/client"
import { AssetFragment } from "../../helper/asset/fragments"

export const HomePageSlideLinkFragment = gql`
  fragment HomePageSlideLinkFragment on ComponentHomepageSlideLinks {
    title
    paragraph
    url
    thumbnail {
      ...AssetFragment
    }
  }
  ${AssetFragment}
`

export const HomePageSlideFragment = gql`
  fragment HomePageSlideFragment on ComponentHomepageSlide {
    smallTitle
    bigTitle
    buttonTitle
    buttonUrl
    backgroundMedia {
      ...AssetFragment
    }
    linkedItemsCollection (limit: 10) {
      items {
        ...HomePageSlideLinkFragment
      }
    }
  }
  ${AssetFragment}
  ${HomePageSlideLinkFragment}
`

export const HomePageFragment = gql`
  fragment HomePageFragment on ComponentCoreHomePage {
    title
    homePageSlidesCollection (limit: 5) {
      items {
        ...HomePageSlideFragment
      }
    }
  }
  ${HomePageSlideFragment}
`