import React, { useState, useEffect } from 'react'
import { client } from '../../store/client'
import { ComponentType } from './models'
import { Components } from './components'

interface ComponentLoaderProps {
  component: ComponentType
}

const getComponentData = async (id: string, query: any) => {
  try {
    return await client.query({ query, variables: { id }}).then(result => result.data) 
  } catch (err) {
    console.log(err)
  }
}

export function ComponentLoader({component}: ComponentLoaderProps) {
  const componentIndex = Components[component.__typename]
  const [componentState, setComponentState] = useState<any>(null)

  useEffect(() => {
    if (componentIndex?.query) {
      getComponentData(component.sys.id, componentIndex?.query)
      .then(data => setComponentState(data))
    }
  }, [componentIndex, component])

  const ComponentToRender = componentIndex?.component

  // if (componentState) {
  //   console.log(`${component.__typename} : ${componentState?.__typename}`)
  //   console.log(componentState)
  // }
  

  return (
    <React.Fragment>
      { ComponentToRender && componentState && (
        <ComponentToRender component={componentState} /> 
      )}
    </React.Fragment>
  )
}