import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { TitleSize, TextCentered } from './models'

interface TextProps {
  title?: string,
  largeTitle?: string,
  titleSize?: TitleSize,
  textCentered?: TextCentered,
  column?: boolean,
  paragraph?: string,
  lines?: boolean,
  rows?: number,
  accent?: boolean
  titleColour?: string
}

export const Text = ({title, largeTitle, titleSize = 'Heading 1', textCentered, titleColour, column, paragraph, lines, accent, rows}: TextProps) => (
  <>
  <div className={`paragraph-container${ textCentered ? ` ${textCentered}` : '' }`}>
    <div className={`content-animation${rows ? " capped" : ""}`} style={{
      lineClamp: rows ? rows : 10000,
      WebkitLineClamp: rows ? rows : 10000
    }}>
      {title && <Title title={title || ""} largeTitle={largeTitle} titleSize={titleSize} accent={accent} titleColour={titleColour} />} 
      {paragraph && <Paragraph paragraph={paragraph || ""} column={column || false} accent={accent} /> }
    </div>
    { lines && <span className={`paragraph-line${ textCentered ? ` ${textCentered}` : '' }${accent ? " white-line" : ""}`}></span> }
  </div>
  </>
)

const Title = ({title, largeTitle, titleSize = "Heading 1", titleColour, accent}: TextProps) => (
  <>
    {{
      "Heading 1": <h1 style={{color: titleColour ? titleColour : ""}} className={`heading-1${accent ? " accent" : " primary"}`}>{ title }{largeTitle && <React.Fragment><br/><span className="large-title">{largeTitle}</span></React.Fragment>}</h1>,
      "Heading 2": <h2 style={{color: titleColour ? titleColour : ""}} className={`heading-2${accent ? " accent" : " primary"}`}>{ title }{largeTitle && <React.Fragment><br/><span className="large-title">{largeTitle}</span></React.Fragment>}</h2>,
      "Heading 3": <h3 style={{color: titleColour ? titleColour : ""}} className={`heading-3${accent ? " accent" : " primary"}`}>{ title }{largeTitle && <React.Fragment><br/><span className="large-title">{largeTitle}</span></React.Fragment>}</h3>,
      "Heading 4": <p className={`heading-4${accent ? " accent" : " primary"}`}>{ title }</p>
    }[ titleSize ]}
  </>
)

const Paragraph = ({paragraph = "", column, accent}: TextProps) => {
  var html = paragraph.replace(/\[([^\]]+)\]\(([^\)]+)\)/, '<a href="$2" target="_blank">$1</a>');
  return (
    <p className={`paragraph${accent ? " accent" : " primary"}${column ? " two-column" : ""}`}>{ ReactHtmlParser(html) }</p>
  )
}