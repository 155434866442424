import { gql } from "@apollo/client"
import { HomePageFragment } from "../../schema"

export const QUERY_HOME_PAGE_BY_ID = gql`
  query ($id: String!) {
    componentCoreHomePage(id: $id) {
      ...HomePageFragment
    } 
  }
  ${HomePageFragment}
`

export default QUERY_HOME_PAGE_BY_ID