import { gql } from "@apollo/client";

export const TimelineFragment = gql`
  fragment TimelineFragment on ComponentCoreTimeline {
    title
    titleSize
    momentCollection (limit: 10) {
      items {
        year
        title
        paragraph
      }
    }
  }
`