import React, { useState, useEffect } from 'react'
import { client } from '../../store/client'
import { useAppDispatch } from '../../store/hooks'
import { Page } from '../../store/slices/pages/models'
import { setFooterState } from '../../store/slices/settings/slice'
import { ComponentLoader } from '../componentLoader'
import { ComponentType } from '../componentLoader/models'
import { QUERY_PAGE_COMPONENTS_BY_ID } from './query'

interface PageLoaderProps {
  page: Page
}

const getPageComponents = async(pageId: string) => {
  try {
    return client.query({ query: QUERY_PAGE_COMPONENTS_BY_ID, variables: { id: pageId } }).then(result => result.data)
  } catch(err) {
    console.error(err)
  }
}

function PageLoaderEvent({page}: PageLoaderProps) {
  const dispatch = useAppDispatch()
  const [pageComponents, setPageComponents] = useState<ComponentType[] | null>(null)

  useEffect(() => {
    setPageComponents(null)
    dispatch(setFooterState(page.fullscreen))
    console.log("Loading2")
    console.log(pageComponents)
    getPageComponents(page.sys.id)
    .then((data) => { 
      setPageComponents(data.page.componentsCollection.items as ComponentType[]) 
    })
  }, [page, setPageComponents, dispatch])

  
  
  return (
    <div className="page-wrapper">
      <div className="componets">
        {pageComponents && pageComponents.map((component: ComponentType, key: number) => (
          <ComponentLoader key={key} component={component}  />
        ))}
      </div>
    </div>
  )

}

export const PageLoader = React.memo(PageLoaderEvent)