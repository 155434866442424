import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../../store/hooks";
import { Navigation } from "../../../../../../store/slices/settings/model";
import { getSettingsNavigation } from "../../../../../../store/slices/settings/slice";

interface SideMenuProps {
  navState: boolean
  setNavState: (navState: boolean) => void
}

export const SideMenuView = ({navState, setNavState}: SideMenuProps) => {
  const navigation: [Navigation] | null = useAppSelector(getSettingsNavigation)

  return (
    <div className={`side-navigation${navState ? " active" : ""}`}>
      <div className="menu">
        <ul>
          {navigation && navigation.map((item, key) => {
            if (item.url.includes("http") || item.url.includes("www.") ) {
              return (
                <a href={item.url} key={key}>
                   <li onClick={() => setNavState(false)}>{item.title}</li>
                </a>
              )
            }
              
            return (
              <Link to={item.url} key={key}>
                <li onClick={() => setNavState(false)}>{item.title}</li>
              </Link>
            )
            
          })}
        </ul>
      </div>

      <div className="overlay" onClick={() => setNavState(!navState)}></div>
    </div>
  )
}