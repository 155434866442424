import React from "react";
import MediaQuery from "react-responsive";
import { VerticalSpacerType } from "./models";

interface VerticalSpacerProps {
  component: {
    componentCoreVerticalSpacer: VerticalSpacerType
  }
}

export function VerticalSpacer ({component}: VerticalSpacerProps) {
  const { space, mobileSpace } = component.componentCoreVerticalSpacer

  return (
    <React.Fragment>
      {mobileSpace !== null ? (
        <React.Fragment>
          <MediaQuery minWidth={760}>
            <div className="vertical-spacer" style={{width: "100%", height: space + "px"}}></div>
          </MediaQuery>
          <MediaQuery maxWidth={760}>
            <div className="vertical-spacer" style={{width: "100%", height: mobileSpace + "px"}}></div>
          </MediaQuery>
        </React.Fragment>
      ): (
        <div className="vertical-spacer" style={{width: "100%", height: space + "px"}}></div>
      )}
    </React.Fragment>
  )
}

export default VerticalSpacer